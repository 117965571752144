<script setup lang="ts">
const config = useAppConfig();
const sidebarWidth = ref(`${config.showSidebar ? 300 : 0}px`);
</script>

<template>
  <div class="w-100 h-100 flex flex-row">
    <Sidebar :sidebar-width="sidebarWidth" />
    <div
      class="h-min-[100vh] flex flex-col w-full"
    >
      <NavBar :offset="sidebarWidth" />
      <main>
        <slot />
      </main>
    </div>
  </div>
</template>

<style scoped>
main {
  @apply max-w-7xl px-8 py-4 ml-[v-bind(sidebarWidth)];
}
</style>
