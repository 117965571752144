import { UserRole } from '@/models/user';

interface Route {
  path: string;
  name: string;
  icon?: string;
}

export const useAvailableRoutes = async () => {
  const routes = useRouter().getRoutes();
  const userData = await useUserStore(useNuxtApp().$pinia).getUserData();

  return routes
    .sort((a, b) => a.path > b.path ? 1 : -1)
    .filter((route) => {
      // Show only signin if user is not logged in
      if (!userData) {
        return route.path === 'user/signin';
      }

      // Remove routes that have parameters
      if (route.path.includes(':')) {
        return false;
      }

      // Admin Routes
      if (route.path.startsWith('/admin')) {
        return userData?.role === UserRole.ADMIN;
      }

      // Client Routes
      if (route.path.startsWith('/client')) {
        return userData?.role === UserRole.CLIENT;
      }

      // Shared Routes
      return false;
    })
    .map((route) => {
      // TODO: Add aliases to routes (to use as names)
      const name = (
        route.name?.valueOf()
          .toString()
          .split('-') ?? []
      )
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return {
        path: route.path,
        name,
        icon: route.meta?.icon
      } as Route;
    });
};
