<script setup lang="ts">
defineProps({
  sidebarWidth: {
    type: String,
    required: true
  }
});
const config = useAppConfig();
const routes = await useAvailableRoutes();
</script>

<template>
  <div>
    <div
      v-if="config.showSidebar"
      class="sidebar"
    >
      <div
        v-for="route in routes"
        :key="route.path"
        class="flex flex-col ml-4 mr-12"
      >
        <NuxtLink
          :to="route.path"
          class="w-full btn my-4 flex flex-row items-center justify-start"
        >
          <component :is="route.icon" v-if="route.icon" class="mr-4 w-5 h-5" />
          <span>
            {{ route.name }}
          </span>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sidebar {
  @apply bg-gradient-to-b from-accent-50 to-accent-300 shadow-md shadow-gray-700 py-8 h-screen w-[v-bind(sidebarWidth)] z-[999] rounded-r-3xl fixed;
}

.btn.router-link-exact-active {
  @apply from-secondary-600 to-secondary-700;
}
</style>
